import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        name: 'app-home',
        path: '/',
        component: () => import(/* webpackChunkName: "home" */ './components/home.vue')
    },
    {
        name: 'app-about',
        path: '/about',
        component: () => import(/* webpackChunkName: "about" */ './components/about.vue')
    },
    {
        name: 'app-inspire',
        path: '/inspire',
        component: () => import(/* webpackChunkName: "about" */ './components/inspire.vue')
    },
    {
        name: 'app-founders',
        path: '/founders',
        component: () => import('./components/founders.vue')
    },
    {
        name: 'camille-hearst',
        path: '/founders/camille-hearst',
        component: () => import('./components/camille-hearst.vue')
    },
    {
        name: 'jason-mayden',
        path: '/founders/jason-mayden',
        component: () => import('./components/jason-mayden.vue')
    },
    {
        name: 'app-network',
        path: '/network',
        component: () => import(/* webpackChunkName: "network" */ './components/network.vue')
    },
    {
        name: 'app-tos',
        path: '/terms-of-service',
        component: () => import(/* webpackChunkName: "tos" */ './components/tos.vue'),
    },
    {
        name: 'app-access',
        path: '/access',
        component: () => import(/* webpackChunkName: "access" */ './components/access.vue'),
    },
    {
        name: 'app-privacy',
        path: '/privacy-policy',
        component: () => import(/* webpackChunkName: "privacy" */ './components/privacy.vue'),
    },
    {
        name: 'app-investments',
        path: '/investments',
        component: () => import(/* webpackChunkName: "investments" */ './components/investments.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to) {
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: 'smooth',
        }
      }
      return { x: 0, y: 0 }
    }
});

export default router;
