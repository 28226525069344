<template>
  <footer class="main-footer">
      <div class="wrapper">
        <div class="logo-copyright">
            &copy; Authentic Ventures
        </div>
        <div class="footer--nav">
            <router-link :to="{ name: 'app-about' }">
              About
            </router-link>
            <router-link :to="{ name: 'app-founders' }">
              Companies
            </router-link>
            <router-link :to="{ name: 'app-inspire' }">
              Industries
            </router-link>
            <router-link :to="{ name: 'app-network' }">
              Network
            </router-link>
            <a href="mailto:info@authentic-ventures.com">Contact</a>
            <router-link :to="{ name: 'app-privacy' }">
              Privacy
            </router-link>
            <router-link :to="{ name: 'app-tos' }">
              Terms
            </router-link>
        </div>
      </div>
  </footer>
</template>

<script>
  export default {
    name: 'app-footer',
  }
</script>
