import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Import Foundation dependencies
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'what-input';
import 'foundation-sites';

const app = createApp(App);
app.use(router);
app.mount('#app');

// Initialize Foundation
$(document).foundation();
