<template>
  <div id="app">
    <app-header></app-header>
    <main><router-view></router-view></main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import appHeader from './components/global/header.vue';
import appFooter from './components/global/footer.vue';

export default {
  name: 'App',
  components: {
    'app-header': appHeader,
    'app-footer': appFooter,
  }
}
</script>

<style lang="scss">
  @import './assets/css/settings.scss';
  @import 'foundation-sites/scss/foundation.scss';
  @import 'motion-ui/src/motion-ui.scss';
  
  @include foundation-global-styles;
  @include foundation-menu;
  @include foundation-top-bar;
  @include foundation-xy-grid-classes;
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  @include foundation-visibility-classes;
  @include foundation-flex-classes;
  @include motion-ui-transitions;
  @include motion-ui-animations;

  // @import 'font-awesome/scss/font-awesome.scss';
</style>
